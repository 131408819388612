import React from "react";

import { Link } from "gatsby"

const FourOhFour = () => {

    return(
        <div className="about h-100">
            <div className="row h-100">
                <div className="col-xl-24 col-lg-24 aboutText">
                    <h1 className="aboutText">404: Not Found</h1>
                    <p className="aboutText">This site doesn't exist.<br></br>
                    Click the links to let us shed some light on our <Link className="fourohfourLink" to="/">work</Link>, on <Link className="fourohfourLink" to="/about">us</Link> or just <Link className="fourohfourLink" to="/contact">say hello</Link>.</p>
                </div>
            </div>
        </div>

    );
};

export default FourOhFour;